import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';

import { Home } from './pages/Home';
import { Dashboard } from './pages/Dashboard';
import { Workouts } from './pages/Workouts';
import { Workout } from './pages/Workout';
import { Tutorial } from './pages/Tutorial';

import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';

const LoggetOutRouteWrapper = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar />
      {children}
      <CookieConsent
        location="bottom"
        style={{ background: '#18181b' }}
        cookieName="acceptCookies"
        buttonText={t('standalone.cookiesButton')}
        buttonStyle={{
          background: '#16a34a',
          color: '#f9fafb',
          fontSize: '14px',
        }}
        expires={150}
      >
        {t('standalone.cookies')}
      </CookieConsent>
      <Footer />
    </>
  );
};

const RouteWrapper = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Navbar isLoggedInRoute />
      {children}
      <CookieConsent
        location="bottom"
        style={{ background: '#18181b' }}
        cookieName="acceptCookies"
        buttonText={t('standalone.cookiesButton')}
        buttonStyle={{
          background: '#16a34a',
          color: '#f9fafb',
          fontSize: '14px',
        }}
        expires={150}
      >
        {t('standalone.cookies')}
      </CookieConsent>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <LoggetOutRouteWrapper>
        <Home />
      </LoggetOutRouteWrapper>
    ),
  },
  {
    path: '/login/:redirectUrl?',
    element: (
      <LoggetOutRouteWrapper>
        <Home />
      </LoggetOutRouteWrapper>
    ),
  },
  {
    path: '/dashboard',
    element: (
      <RouteWrapper>
        <Dashboard />
      </RouteWrapper>
    ),
  },
  {
    path: '/workouts',
    element: (
      <RouteWrapper>
        <Workouts />
      </RouteWrapper>
    ),
  },
  {
    path: '/workout/:workoutId?',
    element: (
      <RouteWrapper>
        <Workout />
      </RouteWrapper>
    ),
  },
  {
    path: '/sync',
    element: (
      <RouteWrapper>
        <Tutorial />
      </RouteWrapper>
    ),
  },
]);

function App() {
  const { t } = useTranslation();
  const isLocalhost = window.location.hostname === 'localhost';

  return (
    <>
      <Helmet>
        <title>{t('appName')}</title>
        {isLocalhost ? (
          <script type="text/javascript">
            {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};
            var r=document.createElement("script");
            r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
            var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);
            for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
            heap.load("3307113423");`}
          </script>
        ) : (
          <script type="text/javascript">
            {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};
            var r=document.createElement("script");
            r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
            var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);
            for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
            heap.load("1715548186");`}
          </script>
        )}
      </Helmet>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
