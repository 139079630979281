import React from 'react';
import ReactDOM from 'react-dom/client';
import { NextUIProvider } from '@nextui-org/react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

import './utils/i18n.js';

import App from './App.jsx';
import './index.css';

Sentry.init({
  dsn: 'https://baa135d3a6cbf51cee724f1023044e53@o4506814380769280.ingest.sentry.io/4506814382342144',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/meutreino\.fit/,
    /^https:\/\/myworkoutskill\.com/,
  ],
  // Session Replay
  // replaysSessionSampleRate: 0.1, // ORIGINAL: 0.1 - This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 1.0, // ASSIM TÁ 100% o replay rate. This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { VITE_GRAPHQL_URL } = import.meta.env;

const httpLink = createHttpLink({
  uri: VITE_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const authToken = localStorage.getItem('authToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: authToken ? `bearer ${authToken}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { pathname, search } = window.location;

  client.resetStore();
  localStorage.removeItem('authToken');

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (
        !/^\/login/.test(pathname) &&
        extensions &&
        ['UNAUTHENTICATED', 'FORBIDDEN'].includes(extensions.code)
      ) {
        window.location = `/login${pathname}${search}`;
      }
    });
  }

  if (networkError) {
    const { statusCode } = networkError;
    const { pathname, search } = window.location;

    if (!/^\/login/.test(pathname) && [401].includes(statusCode)) {
      window.location = `/login${pathname}${search}`;
    }
  }
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <NextUIProvider>
      <main className="dark text-foreground bg-background">
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </main>
    </NextUIProvider>
  </React.StrictMode>
);
